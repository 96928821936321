import React from "react"
import { Link } from "gatsby"
import Layout from "../layouts/layout"
import SEO from "../components/seo"
import headerImage from '../images/contact-us-header.jpg'

const PageContent = (props) => (
  <Layout location={props.location}>
    <SEO title="Contact Us" keywords={[`gatsby`, `application`, `react`]} />
    <div className="container py-5">
      <div className="row">
        <div className="col-12 col-md-10 offset-md-1">
        <div id="headerImage2" className="headerImage py-5 mb-5">Contact Us</div>
        <h4>FOR CAMPS</h4>
        <p>Interested in getting your camps listed? Have an update to your listed camp? Have another comment or question? Email us at <a href="mailto:hello@simplesummers.com">hello@simplesummers.com</a>.</p>
        <h4>FOR PARENTS AND CAREGIVERS</h4>
        <p>Have a question, comment, or suggestion? Think we missed a camp? Something not working as expected? Just want to say hi? Email us at <a href="mailto:hello@simplesummers.com">hello@simplesummers.com</a>.</p>
        <p>Someone will get back to you within 48 hours.</p>
        </div>
      </div>
    </div>

  </Layout>
)
// Post-Submission Copy: Thank you for reaching out to Simple Summers! We will be in touch within 48 hours!
export default PageContent
